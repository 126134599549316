.container {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20viewBox%3D%220%200%20512%20512%22%20width%3D%22512%22%20height%3D%22512%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cfilter%20id%3D%22noise%22%3E%3CfeTurbulence%20type%3D%22fractalNoise%22%20baseFrequency%3D%220.875%22%20result%3D%22noise%22%20%2F%3E%3CfeColorMatrix%20type%3D%22matrix%22%20values%3D%220%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200.1796875%200%22%20%2F%3E%3C%2Ffilter%3E%3Crect%20filter%3D%22url%28%23noise%29%22%20x%3D%220%22%20y%3D%220%22%20width%3D%22512%22%20height%3D%22512%22%20fill%3D%22transparent%22%20opacity%3D%221%22%20%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(45deg, rgba(38, 40, 46, 0.38) 51%, #071b26 100%);
  background-size: 512px, cover;
  background-position: center, 0% 0%;
  background-repeat: repeat, repeat;
  width: 100%;
  display: flex;
  justify-content: center;
}

.content {
  padding: 4rem 3rem;
  max-width: 100%;
}

@media (max-width: 720px) {
  .content {
    padding: 2.625rem 1.5rem;
  }
}

.header {
  color: #1d4d69;
  font-family: "Quicksand", sans-serif;
  letter-spacing: -0.05rem;
  font-size: 2.75em;
  line-height: 1.25;
  font-weight: 400;
  text-align: center;
}

.body {
  color: rgba(242, 255, 254, 0.969);
  font-size: 1em;
  line-height: 1.75;
  margin: 1.5rem 0 2.25rem 0;
}

.question {
  font-weight: 400;
  background-color: rgba(144, 144, 144, 0.25);
}
