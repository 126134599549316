.container {
  width: 20rem;
  height: 3.25rem;
  border-radius: 1.625rem;
  padding: 0 1rem;
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0.2);
  /* border: 2px solid white;
  color: white; */
}

.container::placeholder {
  color: #bfbfbf;
}

.container:active,
.container:focus {
  outline: none;
}
