.container {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%20width%3D%22512%22%20height%3D%22512%22%20preserveAspectRatio%3D%22none%22%3E%3Cpath%20d%3D%22M%200%20256%20L%20512%20256%20M%20256%200%20L%20256%20512%22%20stroke%3D%22rgba(53, 99, 50, 0.6)%22%20stroke-width%3D%221.01px%22%20vector-effect%3D%22non-scaling-stroke%22%20stroke-linecap%3D%22square%22%20%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(
      152deg,
      rgba(27, 25, 38, 0.502) 100%,
      rgba(62, 97, 140, 0.502) 100%
    ),
    url("../assets/home_bg.jpeg");
  background-size: 26px, cover, cover;
  background-position: center, 0% 0%, center;
  background-repeat: repeat, repeat, repeat;
  background-attachment: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
}

.small_text {
  font-size: 16px;
  line-height: 15px;
}

.content {
  width: 100%;
  padding: 5.25rem 3rem;
  color: white;
}

@media (max-width: 720px) {
  .content {
    padding: 4rem 1.5rem;
  }
}

.col_title {
  font-family: Quicksand, sans-serif;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
}

.graph_col {
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
}

.graph_container {
  height: 50vh;
}

.graph_text {
  color: black;
  font-weight: 500;
  font-family: Quicksand, sans-serif;
}

.retake {
  font-family: Quicksand, sans-serif;
  font-size: 20px;
  color: var(--primary-light);
}

.retake:hover {
  cursor: pointer;
  color: var(--primary);
}

.add_btn {
  padding: 5px;
  border-radius: 8px;
  transition: background-color 0.2s;
}

.add_btn:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.212);
}

.family_link {
  color: var(--primary-light);
}

.family_link:hover {
  color: var(--primary);
}
