.container {
  background-image: linear-gradient(
      to top,
      rgba(230, 216, 216, 0.49),
      rgba(230, 216, 216, 0.49)
    ),
    url("../assets/about_bg.jpeg");
  background-position: 0% 0%, bottom left;
  background-repeat: repeat, repeat;
  background-size: auto, cover;
  background-attachment: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
}

.content {
  max-width: 100%;
  padding: 4.25rem 3rem;
}

@media (max-width: 720px) {
  .content {
    padding: 2.625rem 1.5rem;
  }
}

.header {
  color: #1d4d69;
  font-family: "Quicksand", sans-serif;
  letter-spacing: -0.05rem;
  font-size: 2.75em;
  line-height: 1.25;
  font-weight: 400;
  text-align: center;
}

.body {
  color: #662c2c;
  font-size: 1em;
  line-height: 1.875;
  letter-spacing: 0.05rem;
  width: calc(100% + 0.7rem);
  margin: 2.625rem 0;
}

.btn_row {
  margin-top: 1rem;
  margin-bottom: 3.625rem;
}

.btn {
  background-color: white;
  color: black;
  display: flex;
  width: 14.5rem;
  height: 3.25rem;
  text-transform: uppercase;
  font-size: 1em;
  font-family: "Inter", sans-serif;
  letter-spacing: 0.15rem;
  font-weight: 600;
  border-radius: 2rem;
  transition: 0.3s;
}

.btn:hover {
  background-color: rgb(224, 224, 224);
  cursor: pointer;
  text-decoration: none;
  color: black;
}
